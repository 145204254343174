import React from "react"
import { useDispatch } from "react-redux"
import * as Yup from 'yup'
import Firebase from '../../../firebase/index'
import Form from '../../Common/Form/Form'

export default ({setStep}) => {
  let dispatch = useDispatch()
  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .email('Invalid e-mail address.')
      .required('E-mail is required'),
      passwordOne: Yup.string()
      .min(8, 'Password is too short.')
      .required('Password is required.'),
      passwordTwo: Yup.string().oneOf([Yup.ref('passwordOne'), null], 'Passwords do not match.')
      .required('Passwords do not match.'),
      termsOfService: Yup.boolean()
      .required("The terms and conditions must be accepted.")
      .oneOf([true], "The terms and conditions must be accepted.")

  });



  async function onSubmit(values){
    let user = await Firebase.register(values.email, values.passwordOne)
    if(user){
      setStep(2)
    }
    return
  }

  return (
    <>
    <Form onSubmit={onSubmit} validationSchema={validationSchema} initialValues={{
          email: "",
          passwordOne: "",
          passwordTwo: "",
          termsOfService: false
        }} fields={[
            {
                placeholder: 'example@mail.com',
                label: "E-mail",
                type: 'email',
                name: 'email'
            },
            {
                placeholder: 'Your Password',
                label: "Password",
                type: 'password',
                name: 'passwordOne',
            },
            {
                placeholder: 'Confirm Password',
                label: "Confirm Password",
                type: 'password',
                name: 'passwordTwo',
            },
            {
                label: "I agree with Terms and Conditions",
                type: 'checkbox',
                name: 'termsOfService',
                to: '/terms_and_conditions'
            }
        ]} 
        submitButtonText={"Register"}/>
    </>
  )
}
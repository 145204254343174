import { Link } from "gatsby"
import React from "react"
import RightArrowSvg from "../../../assets/svg/chevron-right.svg"
import LeftSideComponent from '../LeftSideComponent/LeftSideComponent'
import RightSideComponent from "../RightSideComponent/RightSideComponent"
import MailSvg from '../../../assets/svg/email.svg'
import AuthMessage from '../AuthMessage/AuthMessage'
import Form from "./Form"
import * as styles from './register.module.scss'

export default _ => {
  const [step, setStep] = React.useState(1)
  return (
    step === 1 ?
    <div className={styles.container}>
      <LeftSideComponent>
        <div className={styles.inner}>
          <h2>Register</h2>
        <Form setStep={setStep}/>
        <div className={styles.login}>
            Already have  an account?
            <Link to={'/login'}><span>Log in here </span><RightArrowSvg /></Link>
          </div>
        </div>
      </LeftSideComponent>
      <RightSideComponent />
    </div> : <AuthMessage icon={<MailSvg />} title={'Check your email'} text={`We’ve sent you an email with instructions to activate your account.`} />
  )
}
import { Link } from 'gatsby'
import React from 'react'
import * as styles from './authmessage.module.scss'

export default ({title, text, icon}) => {
    return(
        <div className={styles.container}>
            <div className={styles.svgDiv}>
                {icon}
            </div>
            <h1>{title}</h1>
            <h2>{text}</h2>
            <Link to={'/'}>Go to Homepage</Link>
        </div>
    )
}
import React from "react"
import Logo from "../../Common/Logo/Logo"
import * as styles from "./leftsidecomponent.module.scss"

export default ({ children }) => {
  return (
    <div className={styles.left}>
      <div className={styles.logoDiv}>
        <Logo />
      </div>
      <div className={styles.children}>
        {children}
      </div>

    </div>
  )
}

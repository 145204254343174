import React from "react";
import * as styles from "./rightsidecomponent.module.scss";
import { StaticImage } from "gatsby-plugin-image";

export default (_) => {
  return (
    <div className={styles.container}>
      <div className={styles.inner}>
        <StaticImage
          src="../../../assets/images/illustrations/3d-two.png"
          alt="Phone image on auth pages"
          placeholder="blurred"
          layout="constrained"
          width={1200}
        />
      </div>
    </div>
  );
};

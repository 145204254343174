import { Link } from 'gatsby'
import React from 'react'
import Logo from '../../../assets/svg/logo.svg'
import * as styles from './logo.module.scss'

export default _ => {
    return(
        <Link to={'/'} style={{textDecoration: 'none'}}>
        <div className={styles.logo}>
          <Logo />
        </div>
        </Link>
    )
}